.login__logo {
	max-width: 200px;
}

.login__fields {
	padding: $m-md;
	padding-top: 0;
}

.login__form-fields {
	display: flex;
}

.login__form {
	max-width: 500px;
	flex-basis: 500px;
	margin: 0 auto;
}

@include media-breakpoint-up(md) {
	.login__graphic {
		background: url('../images/login.jpg') no-repeat center center;
		background-size: cover;
		height: 100vh;
	}
}

@include media-breakpoint-only(sm) {
	.login__fields {
		background: url('../images/login.jpg') no-repeat center center;
		background-size: cover;
		height: 100vh;

		display: flex;
		align-items: center;
		justify-content: center;
	}
	.login__form {
		background: rgba($color: $dark, $alpha: .90);
		padding: $m-md;
		padding-top: 0;
		border-radius: $border-radius;
	}
}

@include media-breakpoint-only(xs) {
	.login__form-fields {
		flex-direction: column;
		.btn {
			margin-top: $m-sm;
		}
	}
	.login__fields {
		height: 60vh;
	}
	.login__graphic {
		background: url('../images/login.jpg') no-repeat center center;
		background-size: cover;
		height: 40vh;
		order: 4;
	}


}