// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base: 			'Gotham';
$headings-font-family: 		$font-family-base;
$headings-font-family-alt: 	$font-family-base;
$buttons-font-family:       $font-family-base;

$font-size-base : 				1rem;
$font-weight-base: 				400;
$headings-font-weight: 			400;
$font-weight-bolder: 			700;

$h1-font-size:					48px;
$h2-font-size:					32px;
$h3-font-size:					28px;
$h4-font-size:					$font-size-base * 1.6;
$h5-font-size:					$font-size-base * 1.3;
$h6-font-size:					$font-size-base;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    sm: 500px,
    md: 800px,
    lg: 1000px,
    xl: 1200px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


$border-radius:               8px;
$border-radius-sm:            8px;
$border-radius-lg:            8px;



// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           16px;
$grid-row-columns:            6;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-border-width: 0;
// $btn-border-width: 0;
$btn-padding-y-lg: 1rem;

// $input-btn-padding-y-lg:      2rem;
// $input-btn-padding-x-lg:      5rem;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: $grid-gutter-width;
$spacers: ();
$spacers: map-merge((
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * 1),
    4: ($spacer * 2.5),
    5: ($spacer * 3.5)
), $spacers);

$m-xs  : map-get($spacers, 2);
$m-sm :  map-get($spacers, 3);
$m-md  : map-get($spacers, 4);
$m-lg  : map-get($spacers, 5);



$z-max:                   1000;