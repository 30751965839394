$teal: 		#199eda;
$blue: 		#0f6bb4;
$navy: 		#005280;
$indigo:    #083c65;

$green: 	#26b787;
$magenta: 	#a20e32;
$orange : 	#e9631b;
$tangerine: #fd7e14;
$peach: 	#ed706f;
$pink:      #e83e8c;
$purple: 	#474052;
$red : 		#ef3743;
$silver : 	#8f8f8f;
$yellow : 	#fed24c;
$purple:   #6f42c1;
$cyan:     #17a2b8;




$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;


$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);


$primary:       $teal;
$secondary:     $yellow;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          #1d202c;


$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
  ),
  $theme-colors
);