// Login Screen
// ------------------------------------------------
.error {
	color: $danger;
}


// Content Box
// ------------------------------------------------
.content-box {
	background: $white;
	border-radius: $border-radius;
	border: solid 1px $border-color;
	border-top: solid 5px $yellow;
	border-bottom: solid 5px $gray-400;
	padding: $m-sm $m-xs;
}


// Search Box
// ------------------------------------------------
.search__header-wrapper {
	padding-top: $m-xs;
}
	.search__header {
		background: $blue;
		background: linear-gradient(0deg, $blue 0%, $teal 100%);
		border-radius: $border-radius;
		border-bottom: solid 5px $indigo;

		@include media-breakpoint-up(sm) {
			display: flex;
			align-items: center;
		}
	}



// Product Listing
// ------------------------------------------------
.product__screen {
	padding-bottom: 100px;
}

.product__item-wrapper {
	transition: 200ms all ease-in-out;
}
	.product__item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include media-breakpoint-up(sm) {
			min-height: 620px;
		}
	}
		// Title and brand logo
		.product__header {
			border-bottom: solid $border-width $border-color;
			background-color: $white;
			margin: 0 -.5rem;
			display: flex;
			align-items: center;

			h2 {
				flex: 1;
			}
		}
			.product__logo {
				max-height: 50px;
				width: auto;
			}

		// Product Thumbnail
		.product__thumbnail-wrapper {
			height: 150px;
		}
			.product__thumbnail {
				max-height: 150px;
				width: auto;
				margin: 0 auto;
				display: block;
			}


		// Table of details
		.table__product-details-wrapper {
			background-color: $gray-100;
		}
			.product__header,
			.table__product-details-wrapper {
				margin: 0 -.5rem
			}
			.table__product-details {
				width: 100%;
				border-collapse: collapse;

				th[scope='row'] {
					text-align: right;
				}
				th[scope='col'] {
					text-align: left;
				}
				td, th {
					padding: 2px 5px;
					margin: 0;
				}
				.table__newrow {
					td, th {
						padding-top: 1rem;
					}
				}
			}




// Products Total
// ------------------------------------------------
.products__totals {
	width: 100%;
	padding: $m-sm 0;

	th[scope=col] {
		border-bottom: dashed 2px $border-color;
	}
	th[scope=row] {
		text-align: right;
	}
	tfoot {
		td, th {
			border-top: dashed 2px $border-color;
		}
	}
	th, td {
		padding: 7px 5px;
	}
	.products__total-name {
		font-weight: bold;
		text-align: right;
		background-color: $gray-100;
		@include media-breakpoint-up(sm) {
			width: 70%;
		}
	}
	.products__totals-row {
		td {
			padding-bottom: 20px;
		}
	}

	.products__total-th {
		text-align: right;
	}

	.totalamount {
		font-size: 1.5rem;
		font-weight: bold;
		text-align: right;
	}
	.platecostname {
		width: 33%;
	}
	.platecostvalue {
		width: 33%;
	}
}



// Navigation
// ------------------------------------------------
.site-logo {
	max-width: 120px;
}

.masthead {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;


	li {
		margin-right: $m-xs;
		display: flex;
		flex: 1;

		&:last-of-type {
			margin-right: 0;
		}

		.btn {
			text-decoration: none;
			display: flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			flex: 1;

			&.is-active {
				background: transparent;
				color: $white;
				border-color: transparent;
			}

			@include media-breakpoint-only(xs) {
				font-size: .8rem;
			}
		}
	}
}
