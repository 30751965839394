.slideInDown {
	@include slideInDown();
}

.fadeIn {
	@include fadeIn();
}
.fadeInDown {
	@include fadeInDown();
}


.animated.delay-200ms {
	animation-delay: 200ms;
}
.animated.delay-400ms {
	animation-delay: 400ms;
}
.animated.delay-600ms {
	animation-delay: 600ms;
}
.animated.delay-800ms {
	animation-delay: 800ms;
}

.animated.delay-1s {
	animation-delay: 1s;
}