// Responsive Text
// -----------------------------------
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
		font-size: $min-font-size;
		@media screen and (min-width: $min-vw) {
			font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
		}
		@media screen and (min-width: $max-vw) {
			font-size: $max-font-size;
		}
		}
	}
}

// Usage
// $min_width: 320px;
// $max_width: 1200px;
// $min_font: 16px;
// $max_font: 24px;

// p {
//   @include fluid-type($min_width, $max_width, $min_font, $max_font);
// }


// Font Families
// -----------------------------------
@mixin font-face($name, $filename) {
	@font-face {
		font-family: $name;
		src: url('#{$filename}.woff') format('woff');
		font-style: normal;
		font-weight: normal;
		 -webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@mixin font-face-extended($name, $weight, $style, $filename) {
	@font-face {
		font-family: $name;
		src:url('#{$filename}.woff') format('woff');
		font-style: $style;
		font-weight: $weight;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}