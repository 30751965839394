html {
  font-size: $font-size-base;
}

body {
  font-family: $font-family-base, sans-serif;
	display: block;
	background-color: $dark;
}



h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
	margin-bottom: $headings-margin-bottom;
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;
}


h1, .h1 { @include fluid-type(320px, 1200px, 24px, 48px);}
h2, .h2 { @include fluid-type(320px, 1200px, 20px, 32px); }
h3, .h3 { @include fluid-type(320px, 1200px, 18px, 16px); }
// h4, .h4 { @include font-size($h4-font-size); }
// h5, .h5 { @include font-size($h5-font-size); }
// h6, .h6 { @include font-size($h6-font-size); }

.text-larger {
  font-size: 1.2rem;
}

img {
  max-width: 100%;
  width: 100%;
  display: inline-block;
  margin-bottom: 0;
}

.strong, strong {
  font-weight: bold;
}