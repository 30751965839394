*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

body {
	display: block;
	background-color: $dark;
}

.container {
	max-width: map-get($grid-breakpoints, xl);
}

.MuiOutlinedInput-root,
.MuiInputLabel-formControl {
	background: $white;
}

.MuiInputLabel-formControl {
	padding: 2px 4px !important;
	border-radius: 3px 3px 0 0;
}

.btn {
	text-transform: uppercase;
	font-family: $buttons-font-family;
	font-weight: bold;
	border-bottom: solid 3px;
	&:hover {
		border-bottom: solid 3px;
	}
}

.btn-secondary {
	border-bottom-color: darken($color: $secondary, $amount: 40%) ;
	&:hover {
		border-bottom-color: darken($color: $secondary, $amount: 40%) ;
	}
}

.btn-primary {
	border-bottom-color: darken($color: $primary, $amount: 40%) ;
	&:hover {
		border-bottom-color: darken($color: $primary, $amount: 40%) ;
	}
}

.flex {
	display: flex;
}
.flex-vertical {
	flex-direction: column;
}
.flex-item {
	flex: 1;
}